@import "../../../../styles/fonts";

.container {
  width: 100%;
  height: 100%;
  width: 945px;
  height: 575px;
  max-width: 945px;
  max-height: 575px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  column-gap: 20px;

  .form_container {
    width: 100%;
    height: 100%;
    border: 1px solid #d0d0d0;

    padding: 37.5px 44.5px 85px 44.5px;
    width: 400px;
    height: 575px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    row-gap: 45px;

    font-family: "Pretendard-Medium";
    font-size: 16px;
    color: #000000;

    form {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-direction: column;

      row-gap: 45px;

      // * 내부 글자 한개 섹션
      section {
        width: 316px;
        height: 59px;

        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;

        row-gap: 10px;

        input {
          border: 1px solid #d0d0d0;
          background-color: #ffffff;
          color: #353535;
          font-size: 14px;
          font-family: "Pretendard-Regular";

          width: 100%;
          height: 30px;

          padding-left: 10px;
        }
      }

      button {
        cursor: pointer;
        margin-left: auto;
        position: relative;
        bottom: 25px; // 25px 위로 올라가기

        width: 90px;
        height: 40px;
        font-size: 14px;
        color: #ffffff;

        border: 0px;
        background-color: #4095a2;
        font-family: "Pretendard-Medium";
      }
    }
  }
}

// * 반응형 제작 *1023)
@media screen and (max-width: 1023px) {
  .container {
    // width: 100%;
    // height: 100%;
    width: 310px;
    height: 1108px;
    max-width: 310px;
    max-height: 1108px;

    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    flex-direction: column;

    row-gap: 15px;

    .form_container {
      width: 100%;
      height: 100%;
      // border: 1px solid #d0d0d0;

      // padding: 37.5px 44.5px 85px 44.5px;
      // width: 400px;
      height: 575px;
      padding: 0px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      row-gap: 45px;

      font-family: "Pretendard-Medium";
      font-size: 14px;
      color: #000000;

      form {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;

        row-gap: 45px;

        width: 270px;

        // * 내부 글자 한개 섹션
        section {
          width: 100%;
          height: 57px;

          display: flex;
          justify-content: center;
          align-items: start;
          flex-direction: column;

          row-gap: 10px;

          input {
            border: 1px solid #d0d0d0;
            background-color: #ffffff;
            color: #353535;
            font-size: 14px;
            font-family: "Pretendard-Regular";

            width: 100%;
            height: 30px;

            padding-left: 10px;
          }
        }

        button {
          cursor: pointer;
          margin-left: auto;
          position: relative;
          bottom: 25px; // 25px 위로 올라가기

          width: 90px;
          height: 40px;
          font-size: 14px;
          color: #ffffff;

          border: 0px;
          background-color: #4095a2;
        }
      }
    }
  }
}

// * 캘린더 49제 반응형
.milestoneLabel {
  font-family: "Pretendard-SemiBold";
  color: #4095a2;
  font-size: 14px;

  // * 반응형 제작 *1023)
  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
}
// * 캘린더 음력 반응형
.lunarDate {
  font-family: "Pretendard-Regular";
  color: #767676;
  font-size: 12px;

  // * 반응형 제작 *1023)
  @media screen and (max-width: 1023px) {
    font-size: 10px;
  }
}
