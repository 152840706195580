// 슬라이드 화살표 색상 변경
.slick-prev:before, .slick-next:before {
  font-family: 'Pretendard-Medium';
  font-size: 48px;
  color: #D0D0D0;
  line-height: 48px;
  position: absolute;
}
.slick-prev:before{
  right: 20px;
}
.slick-next:before {
  left: 20px
}
.slick-prev, .slick-next {
  height: 48px;
}

.slick-slide {
  width: 100%;
}

.slick-list {
  display: flex;
  justify-content: space-between;
}

.slick-track {
  display: flex;
  justify-content: space-between;

}

.slick-slide img {
  display: block;
}

.slick-next:before{
  top: 0%;
}

.slick-prev:before{
  top: 0%;
}

.slick-dots li button:before {
  font-size: 10px;
  color:#D0D0D0;
}

.slick-dots li.slick-active button:before{
  color: #A4A4A4;
}

.slick-dots{
  bottom: -70px;
}

@media (max-width: 1023px) {
  .slick-dots{
    bottom: -35px;
    width: 100%;
    padding-right: 50px;
  }
}