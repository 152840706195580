@import "fonts.scss";
@import "reset.scss";
@import "variables.scss";

.subheading {
    font-family: "Pretendard-SemiBold";
    color: #000;
    font-size: 20px;
    margin-bottom: 30px;
    
    &::before{
        content: "▶ ";
    }
}

.callout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 24px 0;
    gap: 15px;
    border: 1px solid #4095A2;
    font-family: "Pretendard-Medium";
    font-size: 18px;
}

.heading {
    font-family: "Pretendard-SemiBold";
    color: #000;
    font-size: 22px;
    margin-bottom: 10px;

    &::before{
        content: "<";
    }

    &::after{
        content: ">";
    }
}