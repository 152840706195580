@import '../../styles/fonts';
@import '../../styles/variables';



//* 사이드바 컨테이너
.container{
    min-width: 11.875rem; //190px
    height: 100%;
    // background-color: antiquewhite;

    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    font-family: 'Pretendard-Medium';

    .nav_container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;


        //Skdebar의 전체 대제목
        .title_sidebar{
            background-color: #4095A2;
            border-radius: 10px;
            width: 100%;
            min-height: 7.5625rem;
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            font-size: 1.125rem; // 18px
            text-align: center;
            color: #FFFFFF;
            font-family: 'Pretendard-SemiBold';
        }

        // 네비 메뉴들이 있는 컨테이너
        .menu_container{
            width: 100%;
            display: flex;
            flex-direction: column;
            list-style-type: none; // 점 삭제

            padding: 0;
            margin: 0;
            row-gap: 10px;

            // 그 안의 서브 메뉴 1개당 컨테이너
            .subMenu_container{
                display: flex;
                flex-direction: column;
                list-style-type: none; // 점 삭제
                
                
                // 서브메뉴 제목 (ex. 1층, 2층,, )
                .subMenu_title{
                    height: 2.4375rem; //39px
                    border-radius: 5px;
                    display:flex;
                    align-items: center;
                    justify-content: left;

                    box-sizing: border-box;
                    padding: 10px;

                    background-color: #E9F4F6;

                    font-size: 0.9375rem;
                    font-family: 'Pretendard-SemiBold';
                    

                    // &:hover {
                    //      cursor: pointer;
                    //     color: #4095A2;
                    //     border-radius: 5px;
                    //     border: 1.5px solid #4095A2;
                    // }

                    .item_text{ // 아이템의 텍스트 
                        flex: 1;
                    }

                    .item_img{ // 아이템이미지 (평소엔 none)
                        display: none; // 원래는 숨김
                    }


                }



                // 서브메뉴 안의 아이템 컨테이너
                .subMenu_item_container{
                    display: flex;
                    flex-direction: column;
                    list-style-type: none; // 점 삭제
                    padding: 0;
                    // 서브메뉴의 아이템들
                    .subMenu_item{
                        position: relative;
                        cursor: pointer;
                        display:flex;
                        align-items: center;
                        justify-content: left;
                        font-size: 0.9375rem; //15px

                        list-style-type: none; // 점 삭제
                        height: 2.4375rem; //39px
                        // border-radius: 5px;

                        box-sizing: border-box;
                        padding-left: 10px;
                        padding-right: 10px;

                        background-color: #FFFFFF;
                        border-bottom: 1px solid #D0D0D0;

                        &:hover {
                            color: #4095A2;
                            border-radius: 5px;
                            border: 1.5px solid #4095A2;
                        }

                        .item_text{
                            flex: 1;
                        }

                        .item_img{ // 아이템이미지 (평소엔 none)
                            display: none;
                            // margin-left: 2px;
                        }


                        
                    }
                }



            
            }
        }

    }
}


// *아이템이 클릭된 경우
.clicked {
    // font-weight: bold; // 예시: 글자 굵게
    color: #4095A2;
    border: 1.5px solid #4095A2 !important; //border 스타일 강제 적용 (css 우선순위 높이기)
    border-radius: 5px; 
    
}

.clicked_img{
    position: absolute;
    right: 6px;
    display: inline-block;
    // width: 15px;
    // height: 13.3px;
    // width: 6px;
    // width: 6px;
    
    
}

.page {
    display: flex;
    gap: 105px;
    max-width: var(--desktop-width);
    padding-top: 64px;

    .page_content{
        display: flex;
        flex-direction: column;
        width: 100%;

        .nav {
            padding: 35.41px 0 0 0;
            display: flex;
            flex-direction: row;
            font-family: "Pretendard-Medium";
            font-size: 14px;
            color: #707070;
            align-items: center;

            li {
                &::after{
                    content: '>';
                    padding: 0 15px;
                }
                &:last-child::after,
                &:first-child::after{
                    content: '';
                    padding: 0 10px 0 0;
                }
                &:last-child{
                    color: #000;

                }
            }
        }
        h1{
            font-family: "Pretendard-Bold";
            font-size: 22px;
            padding: 40px 0 30px 0;
        }
    }
}


// * 반응형 쿼리 (최대 1023px인 경우)
@media screen and (max-width: 1023px) {
	// aside {
    .container{
        // visibility: hidden;
		display: none;
	}
    .page {
        width: 100%;
        justify-content: center;
        padding-top: 0;
        .page_content{
            max-width: 360px;
            .nav{
                font-size: 11px;
                li {
                    &::after{
                        content: '>';
                        padding: 0 13px;
                    }
                }
            }
            .page_header{
                padding: 40px 0px 20px 0;
                font-size: 16px;
            }
        }

        
    }
}