@import "../../styles/fonts";

.hidden {
  display: none;
  
}

.block {
  display: block !important;

  
}
// * 1. 전체 컨테이너
.topbar_container {
  z-index: 100;
  // position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  position: fixed; // 아이폰 position: -webkit-sticky
  // position: -webkit-sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  color: #000000;
  font-family: "Pretendard-Medium";
  box-sizing: border-box;
  padding: 0;
  height: 7.5rem; //10px
  // column-gap: 30px;

  .navbar_conainer {
    // width: 100%;
    // height: 100%;
    // // background-color: #ff5353;
    // max-width: var(--desktop-width);  // 최대 너비 설정
    // display: flex;
    // box-sizing: border-box;
    // flex-direction: row;
    // align-items: center;
    // // justify-content: start;
    justify-content: space-between; // 아이템 동일한 간격
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: start;
    max-width: 1240px;

    box-sizing: border-box;
    flex-direction: row;

    // // * 로고, 네비 컨테이너 (전체 담은 컨네티어)ㄴ
    // .logo_nav_container{
    //     width: 100%;
    //     height: 100%;
    //     flex: 1;
    //     display: flex;
    //     align-items: center;
    //     justify-content: start;
    //     column-gap: 145px; // 오른쪽 간격 넣기;

    //     box-sizing: border-box;
    //     flex-direction: row;

    // * 로고 이미지 컨테이너
    .logo_container {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      // * 로고 이미지
      .logo_img {
        height: 130px; // 64px
        cursor: pointer;
      }
    }

    // * 2. 메인 메뉴 컨테이너
    .main_container {
      display: flex;
      flex: 1;
      box-sizing: border-box;
      min-width: 50%;

      height: 100%;

      // 메뉴 ul 컨테이너
      .menu_all_container {
        margin: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        column-gap: 60px;
        align-items: center;
        justify-content: start; // 아이템 동일한 간격
        list-style-type: none; // 점 삭제
        position: relative;
        margin-left: 90px;

        // 장례식장 소개, 시설현황, 이용안내 진행절차
        .menus_container {
          height: 100%;

          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          // 타이틀
          .menu_title {
            font-family: "Pretendard-Bold";
            cursor: pointer;
            white-space: nowrap;
            font-size: 21px;
            position: relative;
            // padding: 8px 0;

            > a {
              color: inherit; // 링크 색상 제거
              text-decoration: none; // 밑줄 삭제
            }
          }

          &:hover {
            color: #4095a2;
          }
          // * hover 시 밑줄
          &:hover > .menu_title::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -7px; /* 밑줄 간격 설정 */
            height: 3px; /* 밑줄 굵기 설정 */
            background-color: #4095a2; /* 밑줄 색상 */
            border-radius: 3px;
          }

          // menu_title에 hover 시 sub_menus가 보이게 설정
          &:hover > .sub_menus {
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: stretch;
            column-gap: 202px;
          }

          // * 서브메뉴 뒷배경
          .sub_menus::before {
            content: "";
            position: absolute;
            top: 0;
            left: -100vw; /* 화면의 가장 왼쪽으로 이동 */
            right: 0; /* 화면의 오른쪽 끝까지 확장 */
            width: 200vw; /* 너비를 직접 설정하지 않고 left, right로 확장 */
            height: 100%;
            background-color: #ffffff;
            // padding: 40px; //40px
            // min-width: 200px;

            // box-sizing: border-box;

            border: 1px solid #d0d0d0;
            z-index: -1;

            // padding: 40px; //40px
          }

          // * 서브 메뉴들 배경 (소개, 경영이념, 인사말, 오시는길 ... )
          .sub_menus {
            display: none;
            position: absolute;

            top: 120px;
            left: 0px;

            // background-color: #FFFFFF;
            list-style: none;

            min-width: 200px;

            box-sizing: border-box;
            padding: 40px; //40px
            // border: 1px solid #D0D0D0;
            // // * 서브 메뉴

            // * 서브 메뉴들 컨테이너 (소개, 경영이념, 인사말, 오시는길 ... )
            .sub_sub_menus {
              display: flex;
              flex-direction: column;
              padding-left: 20px;
              text-align: left;

              position: relative;
              left: -50%;

              position: relative;
              // * 옆의 설명 선
              &::before {
                content: "";
                position: absolute;
                height: 100%;
                // height: calc(100% - 8px); // 100% + 7px을 적용
                max-width: 2px;
                width: 2px;
                top: 0px;
                left: 0px;

                background-color: #eaeaea;
                border-radius: 2px;
              }
              // 서브메뉴 큰제목
              .sub_menu_title {
                height: 24px;
                font-size: 20px;
                font-family: "Pretendard-SemiBold";
                color: #000000;
                text-decoration: none; // 밑줄 삭제
                font-weight: bold;
                margin: 0 0;
                margin-bottom: 35px; //35px
                white-space: nowrap;
                // margin: 0.5rem 0px;

                // &:hover{
                //     color: #4095A2;
                // }
              }

              // *서브메뉴 소제목
              .sub_menu_items {
                white-space: nowrap; // 줄바꿈 안되게.
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: left;
                justify-content: center;
                font-size: 19px;
                font-family: "Pretendard-SemiBold";

                // * 서브 메뉴 소제목 아이템
                .sub_menu_item {
                  height: 23px;
                  display: flex;
                  flex-direction: column;
                  text-align: left;
                  justify-content: center;
                  margin-bottom: 0 0;

                  > a {
                    text-decoration: none; // 밑줄 삭제
                    color: #767676;

                    &:hover {
                      color: #4095a2;
                    }
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }

                  cursor: pointer;
                }
              }
            }
          }
        }
        // }
      }
    }

    // * 3. 메뉴 더 보기 컨테이너
    .add_menu_container {
      //   margin-right: 84.73px;
      // flex: 1;
      .menu_img {
        // display: none;
        // margin-left: auto;
        // padding-left: auto;
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
      }
    }
  }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/

// 세로모드 모바일 디바이스 (가로 해상도가 576px 보다 작은 화면에 적용)
@media screen and (max-width: 1023px) {
  //* 전체 헤더 컨테이너
  .topbar_container {
    // min-width: 360px;
    // background-color: #95cdd5;
    height: 4.0625rem; // 65px
    width: 100vw;

    //* 헤더 안에 한번 더 감싸는 네비 컨테이너
    .navbar_conainer {
      // flex-direction: column;
      column-gap: 0px;
      justify-content: space-between;
      // * 울산하늘공원 로고 이미지 컨테이너
      .logo_container {
        position: relative;
        left: 25px;
        height: 4.0625rem; // 65px

        .logo_img {
          height: 60px; // 64px // * 왜 이미지로 사이즈가 바뀜?
        }
      }

      // * 2. 메인 메뉴 컨테이너(햄버거 제외)
      .main_container {
        min-width: 30px;
        align-items: start;
        // width: 1px;
        // display: none;
        // background-color: #F8F8F8;

        .menu_all_container {
          position: fixed;
          display: none; // * 우선 가려둔다. 햄버거 메뉴 클릭시 메뉴들을 보여줌
          top: 65px;
          left: 0;
          width: 150px;
          height: 100%;
          margin-left: 0;


          background-color: #f8f8f8;
          border-top: 1px solid #d0d0d0;
          border-right: 1px solid #d0d0d0;
          // display: ;
          // * 위치 왼쪽으로 이동

          // * 새로로 정렬 (장례식장 소개, 시설 현황, 이용 안내, 장례 안내, 공지사항
          flex-direction: column;
          align-items: start;
          text-align: start;

          // 장례식장 소개, 시설현황, 이용안내 진행절차
          .menus_container {
            width: 150px;
            height: 44px;
            color: #000000;

            // position: relative;
            // 타이틀 (장례식장 소개, 시설 현황, 이용 안내, 장례 안내, 공지사항
            .menu_title {
              width: 100%;
              font-family: "Pretendard-SemiBold";
              text-align: start;
              cursor: pointer;
              font-size: 16px;
              box-sizing: border-box;
              padding-left: 25px;

              // color: #000000;
              // padding: 8px 0;
            }

            &:hover {
              background-color: #4095a2;
              color: #ffffff !important;
            }

            &:hover > .menu_title::after {
              content: url(./arrow.svg); // > 표시
              position: relative;
              top: 1px;
              bottom: 0px;
              height: auto;
              left: 10px;
              background-color: none; /* 밑줄 색상 */
              border-radius: 0px;
            }

            // *menu_title에 hover 시 sub_menus가 보이게 설정
            &:hover > .sub_menus {
              flex-direction: column;
              row-gap: 35px;
            }

            // * 서브메뉴 뒷배경
            .sub_menus::before {
              content: "";
              position: fixed;
              top: 65px;
              left: 150px; /* 화면의 가장 왼쪽으로 이동 */
              right: 0; /* 화면의 오른쪽 끝까지 확장 */
              width: calc(
                100% - 150px
              ); /* 너비를 직접 설정하지 않고 left, right로 확장 */
              // width: 300px;
              height: 100%;
              background-color: #ffffff;
              // padding: 40px; //40px
              // min-width: 200px;

              // box-sizing: border-box;
              // border-left: 1px solid #D0D0D0;
              border-left: 0px;
              border-right: 1px solid #d0d0d0;
              border-bottom: 1px solid #d0d0d0;
              border-top: 1px solid #d0d0d0;
              z-index: -1;

              // padding: 40px; //40px
            }
            // * 서브 메뉴들 배경 (소개, 경영이념, 인사말, 오시는길 ... )
            .sub_menus {
              position: fixed;
              top: 65px;
              left: 150px;
              min-width: 210px;
              padding-left: 20px;
              padding-right: 25px;
              padding-top: 12px;
              padding-bottom: auto;

              // * 서브 메뉴들 컨테이너 (소개, 경영이념, 인사말, 오시는길 ... )
              .sub_sub_menus {
                bottom: 0px;
                left: 0px;
                padding-left: 0px;
                // * 옆의 설명 선
                &::before {
                  display: none;
                }

                // 서브메뉴 큰제목
                .sub_menu_title {
                  height: 18px;
                  font-size: 15px;
                  font-family: "Pretendard-SemiBold";
                  color: #353535;
                  text-decoration: none; // 밑줄 삭제
                  font-weight: normal;
                  margin: 0 0;
                  margin-bottom: 15px; //35px
                  // margin: 0.5rem 0px;

                  // &:hover{
                  //     color: #4095A2;
                  // }
                }

                // *서브메뉴 소제목
                .sub_menu_items {
                  row-gap: 12px;
                  font-size: 14px;

                  // * 서브 메뉴 소제목 아이템
                  .sub_menu_item {
                    height: 17px;
                  }
                }
              }
            }
          }
        }
      }
      // * 3. 메뉴 더 보기 컨테이너
      .add_menu_container {
        // flex: 1;

        padding-right: 25px;

        .menu_img {
          display: block; // 보이게한다.
          cursor: pointer;
        }
      }
    }
  }
}
