@import "../../styles/_slider.scss";

.subTitle{
  color: #4095A2;
  font-family: 'Pretendard-Medium';
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 600;
  line-height: 23.8px;
}

.mainTitle{
  font-family: 'Pretendard-SemiBold';
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 700;
  line-height: 28.6px;
}

.facility-carousel {
  width: 100%;
  margin: auto;
  //justify-content: space-between;
  margin-bottom: 0px;
}

.facilityItem {
  position: relative;
  flex-direction: column;
  display: flex;
  width: 300px;
  display: flex !important;
  align-items: center !important;
  column-gap: 20px !important;
  padding-right: 13.333px;
  cursor: pointer;
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 188px;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .title {
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: #fff;
    font-size: 18px;
    padding: 0px 10px;
    border-radius: 4px;
    text-align: left;
    transition: color 0.3s ease;
    z-index: 2;
  }

  .facilityImage {
    width: 300px;
    height: 188px;
    border-radius: 1px;
    transition: transform 0.3s ease;
    z-index: 0;
  }
}

@media (max-width: 1023px) {
  .facility-carousel{
    padding-left: 68px;
  }
  .facilityItem {
    .facilityImage {
      width: 200px;
      height: 125.3px;
      border-radius: 1px;
      transition: transform 0.3s ease;
    }
    .overlay {
      width: 200px;
      height: 125.3px;
    }
    .title {
      font-size: 16px;
    }
  }
}