@import './fonts';

// 예약전화 : 052)264-1017
@mixin callout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: 60px;
    border: 1px solid #4095A2;
    color: #307B86;
    font-family: "Pretendard-SemiBold";
    font-size: 18px;
    
    span { // 강조 표시
        font-family: "Pretendard-Bold"
    }

    // * 반응형
    @media screen and (max-width:1023px) {
        max-width: 310px;
        font-size: 14px;
    }
}   

//믹스인 사용법 : @include resuable-style;

// > 30만상
@mixin subheading {
    font-family: "Pretendard-SemiBold";
    color: #353535;
    font-size: 18px;
    margin: 0;
    
    &::before{
        content: "▶ ";
    }


    @media screen and (max-width:1200px) {
        font-size: 15px;
    }

}
// 


// * 테이블 X축 스크롤 (반응형)
@mixin tableXscroll {
        // 테이블 영역 크기
    overflow-x: scroll;
    padding-bottom: 15px; // 테이블 컨테이너 아래로 20px의 여백 추가 (스크롤 위치를 아래 15px만큼 더 추가했다.)

    // 스크롤바 전체 크기
    &::-webkit-scrollbar{
        
        width: 310px;
        height: 20px;
        color: #D0D0D0;
        border-top: 1px solid #D0D0D0;
        border-bottom: 1px solid #D0D0D0;
    }

    // 스크롤바 thumb(슬라이더) 스타일
    &::-webkit-scrollbar-thumb{
        width: 100px;
        height: 12px;
        background-color: #E0E0E0;
        border-radius: 50px;
        background-clip: padding-box;
        border: 4px solid transparent;
    }

    // 스크롤바 트랙(thumb이 움직이는 공간) 스타일
    &::-webkit-scrollbar-track{
        
        width: 200px;
        height: 30px;
        border-radius: 50px;
    }

}